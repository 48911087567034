<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
		  <!-- 搜索框 -->
		  <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
			<el-form-item label="名称" label-width="50px" prop="keyword">
			  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
			</el-form-item>
			<el-form-item label="角色" label-width="50px" prop="code">
			   <el-select v-model="queryForm.role_id" placeholder="请选择角色状态">
			      <el-option v-for="item in role_list" :key="item.id" :value="item.id" :label="item.name" />
			   </el-select>
			</el-form-item>
			<el-form-item>
			  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
			  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
			</el-form-item>
		 </el-form>
		 <!--自定义刷新组件-->
		 <el-row :gutter="10" class="mb-1">
			<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
			<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
		  </el-row>
		 <!--表格-->
		  <el-table
			v-loading="loading"
			size="small"
			:data="list"
			element-loading-text="Loading"
			highlight-current-row
			style="width: 100%"
		  >
			<el-table-column label="序号" width="80" align="center">
			  <template slot-scope="scope">
				{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
			  </template>
			</el-table-column>
			<el-table-column label="账号" min-width="110" align="center">
			  <template slot-scope="scope">
				<span>{{ scope.row.username }}</span>
			  </template>
			</el-table-column>
			<el-table-column label="名称" min-width="110" align="center">
			  <template slot-scope="scope">
				<span>{{ scope.row.nickname }}</span>
			  </template>
			</el-table-column>
			<el-table-column label="角色" min-width="110" align="center">
			  <template slot-scope="scope">
				{{ scope.row.role_name }}
			  </template>
			</el-table-column>
			<el-table-column label="状态" min-width="50" align="center">
			  <template slot-scope="scope">
				<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
			  </template>
			</el-table-column>
			<el-table-column align="center" prop="utime" label="时间" min-width="110">
			  <template slot-scope="scope">
				<i class="el-icon-time" />
				<span>{{ scope.row.utime }}</span>
			  </template>
			</el-table-column>
			<el-table-column label="操作" min-width="100" align="center">
			  <template slot-scope="scope">
				<el-tooltip class="item" effect="dark" content="编辑" placement="top">
				  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		        </el-tooltip>
				<el-tooltip class="item" effect="dark" content="删除" placement="top">
				  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
				</el-tooltip>
			  </template>
			</el-table-column>
		  </el-table>
		  <pagination
			:total="total"
			auto-scroll
			:page.sync="queryForm.page"
			:limit.sync="queryForm.limit"
			@pagination="query"
		  />
		</el-card>
		 <!-- 编辑-->
       <el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
          <el-form-item label="账号" prop="username">
            <el-input v-model="form.username" placeholder="请输入账号" type="text" clearable />
          </el-form-item>
		  <el-form-item label="名称" prop="nickname">
		    <el-input v-model="form.nickname" placeholder="请输入名称" type="text" clearable />
		  </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" placeholder="请输入密码" type="password" clearable />
			<small class="text-danger font-s" v-if="form.id">为空表示不修改密码</small>
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model="form.mobile" placeholder="请输入电话" type="text" clearable />
          </el-form-item>
          <el-form-item  label="角色" prop="role_ids">
             <el-select v-model="form.role_ids" multiple placeholder="请选择角色" style="width: 100%">
               <el-option
                 v-for="item in role_list"
                 :key="item.id"
                 :label="item.name"
                 :value="item.id"
                 :disabled="item.status === 2"
               />
             </el-select>
          </el-form-item>
          <el-form-item label="头像" prop="avatar">
			  <el-upload
			    class="upload-border"
			    action="/manage/images/upload"
			    :show-file-list="false"
			    :on-success="uploadSuccess"
			    :before-upload="beforeUpload">
			    <img v-if="form.avatar != ''" :src="form.url" class="w-100 h-100">
			    <i v-else class="el-icon-plus uploader-icon"></i>
			  </el-upload>
          </el-form-item>
         <el-form-item  label="状态">
            <el-radio-group v-model="form.status">
            	<el-radio :label="1">使用</el-radio>
            	<el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="cancel">取 消</el-button>
          <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
        </div>
      </el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'admin',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					status: 0
				},
				role_list:[],
				form:{
					username:'',
					password:'',
					mobile:'',
					role_ids:[],
					status:1,
					avatar:'',
					url:'',
				},
				rules:{
					username: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
					role_ids: [
					  { required: true, message: '请选择角色', trigger: ['blur', 'change'] }
					],
				},
			}
		},
		mounted() {
			this.roleList();
		},
		methods:{
			roleList(){
			  this.axios.get('/manage/admin_role/list').then(res=>{
				this.role_list = res.data
			  })
			},
			uploadSuccess(e){
			   if(e.status){
				 this.form.avatar = e.data.id
				 this.form.url = e.data.url
			   }
			},
			beforeUpload(){},
			reset() {
			  this.form = {
			    username:'',
			    password:'',
			    mobile:'',
			    role_ids:[],
			    status:1,
			    avatar:'',
			    url:'',
			  }
			  this.resetForm('form')
			},
			handleEdit(row) {
			  this.reset()
			  this.open = true
			  this.title = '编辑'
			  // 给角色回显
			  const info = JSON.parse(JSON.stringify(row))
			  const roles = info.role_ids.split(',')
			  info['role_ids'] = roles.map(role => {
				return parseInt(role)
			  })
			  this.form = info
			},
		}
	}
</script>

<style>
</style>